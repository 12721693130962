<template>
   <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <div class="px-5 py-5">
      <v-row justify="center">
        <v-col align-self="center">
          <DataValue
            label="Password"
            :value="encryptedPassword"
            :icon="passwordVisible ? 'eye' : 'eye-off'"
            icon-color="deep-purple lighten-2 cursor-pointer"
            @icon-click="togglePasswordVisibility"
          />
        </v-col>
        <v-col align-self="center">
          <v-select v-model="SelectedContactNumber" :items="selectedContactContactNumbers" placeholder="Please Select"></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-4 mb-1">
        <v-btn  color="accent" text  @click.stop="$emit('click-close')">
        Close
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          v-if="SelectedContactNumber"
          :color="companyColor"
          text
          @click="sendSMS"
        >
          SEND {{ formName }} TO {{ SelectedContactNumber }}
        </v-btn>
        <!-- <v-btn
          v-if="SelectedContactNumber && encryptedPassword === ''"
          :color="companyColor"
          text
        >
          SEND {{ formName }} TO XXXX
        </v-btn> -->
      </v-row>
    </div>


    <v-dialog v-model="needToSetPassword" width="450">
      
      <v-card>
        <v-card-title>
        <v-row justify="space-between">
          <v-col>
            Security Password Error
          </v-col>
        </v-row>
        </v-card-title>


        <div class="pb-5 px-5">
            <v-row >
              <v-col >
                Sorry! User need to set a password first!
              </v-col>
            </v-row>
          </div>


        <v-card-actions>
          <v-btn text @click="needToSetPassword = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from "@/store/api"
import {changesSaved, somethingWentWrong } from "@/helpers/utilities"

export default {
  components: {
    DataValue: () => import("@/components/data-value"),
  },
  props: {
    formName: {
      type: String,
      default: ""
    },
    companyColor: {
      type: String,
      default: "grey"
    },
    selectedCompany: {
      type: String
    },
  },
  data(){
    return {
      needToSetPassword: false,
    valid: true,
    passwordVisible: false,
    contactNumbers: [],
    selectedContactContactNumbers: [],
    SelectedContactNumber: null,
    }
  },
  computed: {
    ...mapGetters("contacts", ["selectedContact"]),
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("company", ["company"]),

    encryptedPassword() {
      const pwd = this.selectedContact.securityPassword ? "**********" : "" // NASA encryption
      return this.passwordVisible ? this.selectedContact.securityPassword : pwd
    },
    msgBySaleTypeId(){
      if (this.selectedCompany== 'utility hive'){
        return 'from Utility Hive'
      } else {
        return 'from CONTRACT'
      }
    }
  },
  mounted(){
    this.contactNumbers.push(this.selectedContact.contactNumber)
    this.contactNumbers.push(this.selectedContact.altContactNumber)
    const validCompanyPhone = this.company.telephone && this.company.telephone.startsWith("07")
    console.log('this.company.telephone', this.company.telephone)
    console.log(validCompanyPhone)
    if(validCompanyPhone) {
      this.selectedContactContactNumbers.push(this.company.telephone)
    }
    this.contactNumbers.forEach((phone) => {
      const validPhone = phone && phone.startsWith("07")
      if(validPhone) {
        this.selectedContactContactNumbers.push(phone)
      }
    })
  },
  methods: {
    ...mapActions("notes", ["fetchNotes"]),

    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible
    },
    async sendSMS(){
      if(!this.encryptedPassword) {
        // alert('Sorry! User need to set a password first!')
        this.needToSetPassword = true
        return false
      }
      const contactId = this.selectedContact.contactId
      const phoneNumber = this.SelectedContactNumber
      const noteMessage = this.selectedCompany== 'utility hive' ? 'Password SMS Sent from Utility Hive' : 'Password SMS Sent from Commercial Support Service'
      const saleTypeId = this.selectedCompany== 'utility hive' ? 2 : 1
      const details = {
        contactId: contactId,
        companyId: this.company.companyId,
        phoneNumber: phoneNumber,
        noteMessage: noteMessage,
        currentUserId: this.userId,
        saleTypeId: saleTypeId

      }

      const response = await api.post(`sendPasswordSMS`, details)
      if (response && response.status === 200){
        changesSaved(`Password Sent to ${this.selectedContact.firstName} ${this.selectedContact.lastName}`)
        setTimeout(() => {
          this.fetchNotes()
        }, 1000)
      } else {
        somethingWentWrong()
      }
    },
  }
}
</script>